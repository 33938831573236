:root {
  --light-gray: #f2f2f2;
  --primary-color: #007aff;
  --gray-light: #7c7c7c;
  --gray-lightest: #acacac;
  --gray-darkest: #1c1c1c;
  --warning-color: #ffcc00;
  --error-color: #ff3b30;
  --success-color: #34c759;
  --border-gray: #dadce0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.vh-100 {
  min-height: 100vh;
}
