.sidebar {
  width: 100%;
  flex-grow: 1;

  .top {
    display: flex;
    align-items: center;
    height: 70px;
    background-color: #f2f2f2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    @media only screen and (min-width: 960px) {
      display: none;
    }
  }

  .back_btn {
    font-size: 16px;
    margin: 0px 15px 15px 10px;
    padding: 10px 5px;
    display: flex;
    justify-content: flex-start;
    text-transform: none !important;
  }

  .sidebar_list {
    margin-top: 15px;
    box-sizing: border-box;
    color: var(--gray-lightest);
    font-size: 16px;

    .sidebar_accordion {
      padding: 10px 0px;
    }

    .link {
      display: block;
      color: var(--gray-lightest);
      padding: 10px 55px;
      outline: none;
    }

    .active_link {
      background-color: #007aff10;
      border-radius: 0px 21px 21px 0px;
    }

    .single_link {
      display: flex;
      align-items: center;
      outline: none;
      color: var(--gray-lightest);
      padding: 10px 0px;
      text-decoration: none;

      & > * {
        &:nth-child(1) {
          margin: 0px 15px;
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }

    .notification {
      display: flex;
      &:after {
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--error-color);
      }
    }

    .link:hover,
    .link:focus,
    .active_link,
    .single_link:hover,
    .single_link:focus,
    .sidebar_accordion:hover,
    .sidebar_accordion div[class*='accordion_item_opened'] {
      color: var(--primary-color);
    }
  }
}
